import { useCallback, useEffect, useState } from 'react'
import useGenericRequest from 'api/utils/next/useGenericRequest'
import { v4 as uuidv4 } from 'uuid'
import type {
  AssistantMessageFE,
  AssistantV2ChatRequestDTO,
  AssistantV2ChatResponseDTO,
  AssistantV2ThreadResponseDTO
} from 'ecosystem'
import { useDispatch, useSelector } from 'shared-redux'
import {
  addError,
  addMessage,
  resetChat,
  selectAssistantErrors,
  selectAssistantHistory,
  selectAssistantInitError,
  selectAssistantIsInit,
  selectAssistantNewMessages,
  selectAssistantSessionId,
  selectAssistantSessionUserId,
  setInitError,
  setIsInit,
  setIsSendingMessage,
  setSessionId,
  setSessionUserId
} from 'shared-redux/state'
import { assistantStorage, isNullable } from 'shared-utils'
import { useRouterChanged } from 'ui/hooks'
import { useUser } from 'auth'
import { useAssistantSize } from '../useAssistantSize'
import { useTextToSpeech } from '../../hooks/useTextToSpeech'

interface HookProps {
  paths: {
    chat: string
    thread: string
    textToSpeech: string
  }
}

export const useAssistantV2 = (props: HookProps) => {
  const dispatch = useDispatch()
  const { paths } = props

  const isInit = useSelector(selectAssistantIsInit)
  const initError = useSelector(selectAssistantInitError)
  const errors = useSelector(selectAssistantErrors)
  const sessionId = useSelector(selectAssistantSessionId)
  const sessionUserId = useSelector(selectAssistantSessionUserId)
  const history = useSelector(selectAssistantHistory)
  const newMessages = useSelector(selectAssistantNewMessages)

  const { isExpanded, toggleSize, shrinkSize, expandSize, isOpen } = useAssistantSize()

  const { user, isLoading: isLoadingUser, isValidating: isValidatingUser } = useUser()

  const [inputValue, setInputValue] = useState('')
  const { fetchNextRoute: sendMessageRequest, isLoading: isSendingMessage } =
    useGenericRequest<AssistantV2ChatResponseDTO>()
  const { fetchNextRoute: getThreadId } = useGenericRequest<AssistantV2ThreadResponseDTO>()

  const _sendMessage = useCallback(
    (text: string, cbBefore?: (text: string) => void) => {
      if (!sessionId) {
        return
      }

      let attempt = 0

      cbBefore?.(text)
      dispatch(setIsSendingMessage(true))

      const repeatSendMessage = async (): Promise<AssistantV2ChatResponseDTO> => {
        attempt += 1

        const res = await sendMessageRequest(paths.chat, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json'
          },
          body: JSON.stringify({
            question: text,
            thread_id: sessionId
          } as AssistantV2ChatRequestDTO)
        }).unwrap()
        if (!res.response) {
          if (attempt <= 1) {
            return repeatSendMessage()
          }

          throw new Error('RESPONSE_IS_EMPTY')
        }
        return res
      }

      return repeatSendMessage()
        .then((res) => {
          dispatch(
            addMessage({
              id: uuidv4(),
              text: res.response,
              products: res.products,
              role: 'assistant',
              created: res.created
            })
          )
        })
        .finally(() => dispatch(setIsSendingMessage(false)))
    },
    [dispatch, paths.chat, sendMessageRequest, sessionId]
  )

  const sendMessage = useCallback(
    (text: string) => {
      const id = uuidv4()

      _sendMessage(text, () => {
        const newMessage: AssistantMessageFE = {
          id,
          role: 'client',
          text,
          created: new Date().toISOString()
        }

        dispatch(addMessage(newMessage))
      })
        ?.then()
        .catch(() => {
          dispatch(addError({ id, error: 'Undelivered' }))
        })
    },
    [_sendMessage, dispatch]
  )

  useEffect(() => {
    if (isLoadingUser || isValidatingUser) {
      return
    }

    if (
      isNullable(user?.customerId) !== isNullable(sessionUserId) ||
      user?.customerId !== sessionUserId
    ) {
      if (!user?.customerId) {
        assistantStorage.removeSessionUserId()
        dispatch(setSessionUserId(null))
      } else {
        assistantStorage.setSessionUserId(user.customerId)
        dispatch(setSessionUserId(user.customerId))
      }

      assistantStorage.removeHistory()
      assistantStorage.removeSessionId()
      dispatch(resetChat())

      return
    }

    if (sessionId) {
      assistantStorage.setSessionId(sessionId)
      return
    }

    const abortController = new AbortController()

    getThreadId(`${paths.thread}${user?.customerId ? `?customerId=${user?.customerId}` : ''}`, {
      signal: abortController.signal
    })
      .unwrap()
      .then(({ thread }) => {
        assistantStorage.setSessionId(thread)
        dispatch(setSessionId(thread))
      })
      .catch(() => void 0)

    return () => {
      abortController.abort()
    }
  }, [
    dispatch,
    getThreadId,
    isLoadingUser,
    isValidatingUser,
    paths.thread,
    sessionId,
    sessionUserId,
    user?.customerId
  ])

  const sendInitRequest = useCallback(() => {
    _sendMessage(user?.customer ? `Hi I'm ${user.customer.firstName}` : '', () => {
      dispatch(setIsInit(false))
      dispatch(setInitError(null))
    })
      ?.then(() => {
        dispatch(setIsInit(true))
      })
      .catch(() => {
        dispatch(setInitError('Service unavailable'))
      })
  }, [_sendMessage, dispatch, user])

  const {
    textToSpeech,
    isLoading: isLoadingTextToSpeech,
    isPlaying: isPlayingTextToSpeech
  } = useTextToSpeech(paths.textToSpeech)

  useEffect(() => {
    if (!sessionId || isInit || isLoadingUser || isValidatingUser) {
      return
    }

    if (history) {
      dispatch(setIsInit(true))

      return
    }

    sendInitRequest()
  }, [dispatch, history, isInit, isLoadingUser, isValidatingUser, sendInitRequest, sessionId])

  // update history in Storage
  useEffect(() => {
    if (!isInit || !history) {
      return
    }

    const filtered = history.filter((message) => !errors[message.id])

    assistantStorage.setHistory(filtered.length ? filtered : null)
  }, [errors, history, isInit])

  const handleRouterChange = useCallback(() => {
    if (isOpen) {
      shrinkSize()
    }
  }, [isOpen, shrinkSize])

  useRouterChanged(({ meta: { isPathChanged } }) => {
    if (isPathChanged) {
      handleRouterChange()
    }
  })

  return {
    sendMessage,
    isSendingMessage,
    setInputValue,
    inputValue,
    isInit,
    history,
    newMessages,
    sendInitRequest,
    initError,
    errors,
    isExpanded,
    toggleSize,
    expandSize,
    shrinkSize,
    textToSpeech,
    isLoadingTextToSpeech,
    isPlayingTextToSpeech
  }
}
