import { type AssistantTextToSpeechRequestDTO } from 'ecosystem'
import { type MutableRefObject } from 'react'

interface TextToSpeechProps {
  fetchUrl: string
  payload: AssistantTextToSpeechRequestDTO
  audioCache: Map<string, string>
  audioRef: MutableRefObject<HTMLAudioElement | null>
  onPlay: () => void
  onEnd: () => void
}

class AudioController {
  private audioRef: MutableRefObject<HTMLAudioElement | null>
  private readonly onPlay: () => void
  private readonly onEnd: () => void

  constructor(
    audioRef: MutableRefObject<HTMLAudioElement | null>,
    onPlay: () => void,
    onEnd: () => void
  ) {
    this.audioRef = audioRef
    this.onPlay = onPlay
    this.onEnd = onEnd
  }

  public playAudio(url: string): Promise<void> {
    if (this.audioRef.current) {
      this.audioRef.current.pause()
      this.audioRef.current.currentTime = 0
    }

    const audio = new Audio(url)
    this.audioRef.current = audio

    audio.addEventListener('playing', this.onPlay)
    audio.addEventListener('pause', this.onEnd)
    audio.addEventListener('ended', this.onEnd)

    return audio.play()
  }
}

class AudioFetcher {
  private readonly fetchUrl: string

  constructor(fetchUrl: string) {
    this.fetchUrl = fetchUrl
  }

  public async fetchAudio(payload: AssistantTextToSpeechRequestDTO): Promise<string> {
    const res = await fetch(this.fetchUrl, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(payload)
    })

    if (res.ok && res.body) {
      const reader = res.body.getReader()
      const chunks: Uint8Array[] = []
      let done = false

      while (!done) {
        const { value, done: readerDone } = await reader.read()
        if (value) {
          chunks.push(value)
        }
        done = readerDone
      }

      const audioBlob = new Blob(chunks, { type: 'audio/mp3' })
      return URL.createObjectURL(audioBlob)
    }
    throw new Error('Failed to fetch the audio')
  }
}

export async function textToSpeech({
  fetchUrl,
  onPlay,
  audioRef,
  audioCache,
  payload,
  onEnd
}: TextToSpeechProps) {
  const { text } = payload

  const audioController = new AudioController(audioRef, onPlay, onEnd)

  if (audioCache.has(text)) {
    const cachedAudioUrl = audioCache.get(text)
    if (cachedAudioUrl) {
      await audioController.playAudio(cachedAudioUrl)
      return
    }
  }

  const audioFetcher = new AudioFetcher(fetchUrl)
  const audioUrl = await audioFetcher.fetchAudio(payload)
  audioCache.set(text, audioUrl)

  await audioController.playAudio(audioUrl)
}
