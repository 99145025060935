import { useCallback, useEffect, useRef, useState } from 'react'
import { type Option } from 'ecosystem'
import { textToSpeech } from '../utils/text-to-speech'

export const useTextToSpeech = (fetchUrl: string) => {
  const audioCacheRef = useRef<Map<string, string>>(new Map())
  const audioRef = useRef<HTMLAudioElement | null>(null)

  const [isLoading, setIsLoading] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [error, setError] = useState<Option<string>>(null)

  useEffect(() => {
    return () => {
      audioRef.current = null
    }
  }, [])

  const handleTextToSpeech = useCallback(
    async (text: string) => {
      setIsLoading(true)
      setError(null)

      try {
        await textToSpeech({
          fetchUrl,
          payload: {
            text,
            voice: 'alloy'
          },
          audioCache: audioCacheRef.current,
          audioRef,
          onPlay: () => {
            setIsLoading(false)
            setIsPlaying(true)
          },
          onEnd: () => setIsPlaying(false)
        })
      } catch (e) {
        if (e instanceof Error) {
          setError(e.message)
        } else {
          setError('Unknown error')
        }
        setIsLoading(false)
      }
    },
    [fetchUrl]
  )

  return {
    textToSpeech: handleTextToSpeech,
    isLoading,
    isPlaying,
    error
  }
}
