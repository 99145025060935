import { type FC, memo, type RefObject, useEffect, useMemo } from 'react'
import { Box, Grid, GridItem, type GridProps, HStack, VStack } from '@chakra-ui/react'
import type { AssistantDTO, Option } from 'ecosystem'
import { LiaWindowMinimizeSolid } from 'react-icons/lia'
import { BiCollapseAlt, BiExpandAlt } from 'react-icons/bi'
import GrandIconButton from 'ui/common/GrandIconButton'
import { useUser } from 'auth'
import Chat from '../Chat'
import type { AssistantContextState } from '../types'
import { AssistantProvider } from '../asssistantContext'
import ChatProducts from '../ChatProducts'
import { Avatar } from '../Avatar'
import { useAssistantV2 } from './useAssistantV2'

const getMessagesElement = () => document.getElementById('assistant-messages')
const handleScrollBottom = () => {
  const chatMessages = getMessagesElement()

  if (!chatMessages) return

  chatMessages.scrollTop = chatMessages.scrollHeight
}

interface MainProps extends GridProps {
  onClose: () => void
  paths: {
    chat: string
    settings: string
    thread: string
    products: string
    textToSpeech: string
  }
  settings?: Option<AssistantDTO>
  inputRef: RefObject<HTMLInputElement>
  components?: AssistantContextState['components']
}

const Main: FC<MainProps> = ({ inputRef, onClose, paths, settings, components, ...props }) => {
  const {
    inputValue,
    setInputValue,
    isSendingMessage,
    sendMessage,
    history,
    newMessages,
    sendInitRequest,
    initError,
    errors,
    isInit,
    isExpanded,
    toggleSize,
    textToSpeech,
    isLoadingTextToSpeech,
    isPlayingTextToSpeech
  } = useAssistantV2({
    paths
  })

  const { user } = useUser()

  useEffect(() => {
    handleScrollBottom()
  }, [isInit])

  useEffect(() => {
    if (!newMessages?.length) {
      return
    }

    const { id, role } = newMessages[newMessages.length - 1]

    if (role === 'client') {
      handleScrollBottom()
      return
    }

    const messageElement = getMessagesElement()?.querySelector(`[data-message-id="${id}"]`)

    if (messageElement) {
      messageElement.scrollIntoView({ behavior: 'smooth' })
    }
  }, [newMessages])

  const assistantProviderValue: AssistantContextState = useMemo(() => {
    return {
      onClose,
      inputValue,
      setInputValue,
      isLoading: isSendingMessage,
      handleSend: sendMessage,
      history,
      inputRef,
      initError,
      reinit: sendInitRequest,
      errors,
      isInit,
      settings,
      components,
      paths
    }
  }, [
    inputValue,
    isSendingMessage,
    sendMessage,
    history,
    inputRef,
    initError,
    sendInitRequest,
    errors,
    isInit,
    onClose,
    setInputValue,
    settings,
    components,
    paths
  ])

  return (
    <AssistantProvider value={assistantProviderValue}>
      <Grid
        gap="0"
        gridTemplateRows="72px calc(100% - 72px)"
        h={{
          base: `calc(${isExpanded ? '90' : '60'}vh - 90px)`,
          sm: `calc(${isExpanded ? '80' : '60'}vh - 90px)`
        }}
        p={0}
        templateColumns="repeat(1, 1fr)"
        w="full"
        {...props}>
        <GridItem borderBottomWidth="1px" className="assistant-header">
          <HStack
            borderTopRadius="md"
            h="100%"
            justifyContent="space-between"
            pl={4}
            pr={1}
            w="100%"
            backgroundImage="linear-gradient(117deg, rgb(49 93 145) 0%, rgba(130, 227, 238, 1) 100%);"
            color="white"
            py={9}>
            <HStack>
              <VStack position="relative">
                <Avatar h="45px" w="45px" imageProps={{ src: settings?.imgUrl, sizes: '100px' }} />
                <Box
                  position="absolute"
                  bottom="0"
                  right="0"
                  w="10px"
                  h="10px"
                  backgroundColor="#00ff00"
                  borderRadius="50%"
                />
              </VStack>

              <VStack alignItems="flex-start" gap={0}>
                <Box fontSize="lg" fontWeight="bold">
                  {settings?.name ?? 'AI assistant'}
                </Box>

                {!!user?.customer && (
                  <Box fontSize="sm">{`Chat with ${user?.customer.firstName ?? 'Customer'}`}</Box>
                )}
              </VStack>
            </HStack>

            <HStack>
              <GrandIconButton
                aria-label={isExpanded ? 'Shrink' : 'Expand'}
                fontSize="xl"
                icon={isExpanded ? <BiCollapseAlt /> : <BiExpandAlt />}
                minW={undefined}
                onClick={toggleSize}
                shadow="none"
                tooltip={isExpanded ? 'Shrink' : 'Expand'}
                variant="ghost"
              />

              <GrandIconButton
                aria-label="Minimise"
                fontSize="2xl"
                icon={<LiaWindowMinimizeSolid />}
                minW={undefined}
                onClick={onClose}
                shadow="none"
                tooltip="Minimise"
                variant="ghost"
              />
            </HStack>
          </HStack>
        </GridItem>

        <GridItem>
          <Chat
            onSpeak={textToSpeech}
            isLoadingSpeech={isLoadingTextToSpeech}
            isPlayingSpeech={isPlayingTextToSpeech}
            isExpanded={isExpanded}
            renderProductsComponent={(products) => <ChatProducts products={products} />}
          />
        </GridItem>
      </Grid>
    </AssistantProvider>
  )
}

export default memo(Main)
