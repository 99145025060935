import type { FC, ReactNode } from 'react'
import { useCallback } from 'react'
import { Box, Grid, GridItem, HStack, IconButton } from '@chakra-ui/react'
import type { AssistantMessageProductDTO } from 'ecosystem'
import { BiMicrophone, BiStop } from 'react-icons/bi'
import dynamic from 'next/dynamic'
import useVoiceAssistant from '../hooks/useVoiceAssistant'
import ChatMessages from './ChatMessages'
import { useAssistantContext } from './asssistantContext'
import ChatTextInput from './ChatTextInput'

const LottiePlayer = dynamic(
  () => import('@lottiefiles/react-lottie-player').then((mod) => mod.Player),
  {
    ssr: false
  }
)

interface ChatProps {
  renderProductsComponent: (products: AssistantMessageProductDTO[] | undefined) => ReactNode
  isExpanded: boolean
  onSpeak: (text: string) => void | Promise<void>
  isLoadingSpeech: boolean
  isPlayingSpeech: boolean
}

const Chat: FC<ChatProps> = ({
  isPlayingSpeech,
  renderProductsComponent,
  isExpanded,
  onSpeak,
  isLoadingSpeech
}) => {
  const stateUpdater = useAssistantContext()
  const { inputValue: value, setInputValue, isLoading, handleSend, isInit, inputRef } = stateUpdater

  const sendMessage = useCallback(
    (text: string) => {
      if (isLoading || !isInit) {
        return
      }

      handleSend(text)
      setInputValue('')
    },
    [handleSend, isInit, isLoading, setInputValue]
  )

  const onVoiceResult = useCallback(
    (transcript: string) => {
      sendMessage(transcript)
    },
    [sendMessage]
  )

  const onVoiceError = useCallback((error: string) => {
    // eslint-disable-next-line no-console -- Expected error print
    console.error('Voice input error:', error)
  }, [])

  const { isRecording, startAudioRecording, stopAudioRecording, isSpeechRecognitionSupported } =
    useVoiceAssistant({
      onResult: onVoiceResult,
      onError: onVoiceError
    })

  return (
    <Grid
      className="ai-widget__chat"
      gap="1"
      h="full"
      templateColumns="repeat(1, 1fr)"
      templateRows="repeat(12, 1fr)"
      w="full">
      <GridItem colSpan={1} rowSpan={11}>
        <ChatMessages
          isPlayingSpeech={isPlayingSpeech}
          isLoadingSpeech={isLoadingSpeech}
          onSpeak={onSpeak}
          renderProductsComponent={renderProductsComponent}
          h="full"
          w="full"
        />
      </GridItem>

      <GridItem pb={4} pt={2} px={2} as={HStack} align="center" colSpan={1} rowSpan={1}>
        {!isRecording ? (
          <ChatTextInput
            ref={inputRef}
            onUpdate={setInputValue}
            onSend={sendMessage}
            value={value}
            isLoading={isLoading}
            isInit={isInit}
          />
        ) : (
          <Box flex={1}>
            <Box
              mx="auto"
              h={10}
              w={{
                base: '85%',
                md: isExpanded ? '25%' : '85%'
              }}>
              <LottiePlayer
                autoplay
                loop
                src="https://lottie.host/6bbc4e12-50e3-49fb-8ce7-9a3dc18218d6/slL5zkHJZv.json"
                style={{ height: '100%', width: '100%' }}
              />
            </Box>
          </Box>
        )}

        {isSpeechRecognitionSupported ? (
          <IconButton
            id="voice-assistant-trigger"
            aria-label="Voice Assistant"
            borderRadius="full"
            bg={isRecording ? 'red.400' : 'primary'}
            icon={isRecording ? <BiStop /> : <BiMicrophone />}
            onClick={() => (isRecording ? void stopAudioRecording() : void startAudioRecording())}
            size="sm"
            zIndex={2}
          />
        ) : null}
      </GridItem>
    </Grid>
  )
}

export default Chat
