import 'regenerator-runtime/runtime'
import { useCallback, useEffect, useState } from 'react'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'

interface UseVoiceAssistantParams {
  onResult: (text: string) => void
  onError: (error: string) => void
}

const useVoiceAssistant = ({ onResult, onError }: UseVoiceAssistantParams) => {
  const [isRecording, setIsRecording] = useState(false)

  const {
    finalTranscript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable
  } = useSpeechRecognition()

  const userLanguage = navigator.language || 'en-US'

  const voiceDictionary = useCallback((availableVoices: SpeechSynthesisVoice[]) => {
    const voices: Record<string, SpeechSynthesisVoice[]> = {
      'sv-SE': availableVoices.filter((voice) => voice.lang === 'sv-SE'),
      'en-US': availableVoices.filter((voice) => voice.lang === 'en-US'),
      'en-GB': availableVoices.filter((voice) => voice.lang === 'en-GB')
    }
    return voices
  }, [])

  useEffect(() => {
    if (!browserSupportsSpeechRecognition) {
      onError('Your browser does not support speech recognition.')
    } else if (!isMicrophoneAvailable) {
      onError('Microphone access denied. Please enable it in your browser settings.')
    }
  }, [
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable,
    onError,
    userLanguage,
    voiceDictionary
  ])

  const startAudioRecording = useCallback(async () => {
    if (!listening) {
      try {
        setIsRecording(true)
        await SpeechRecognition.startListening({
          continuous: false,
          interimResults: true,
          language: userLanguage
        })
      } catch (error) {
        setIsRecording(false)
        onError('Failed to start speech recognition.')
      }
    }
  }, [listening, onError, userLanguage])

  const stopAudioRecording = useCallback(async () => {
    if (listening) {
      try {
        setIsRecording(false)
        await SpeechRecognition.stopListening()
      } catch (error) {
        onError('Failed to stop speech recognition.')
      }
    }
  }, [listening, onError])

  useEffect(() => {
    if (finalTranscript) {
      onResult(finalTranscript)
      void stopAudioRecording()
      resetTranscript()
    }
  }, [finalTranscript, onResult, resetTranscript, stopAudioRecording])

  return {
    isRecording: listening && isRecording,
    startAudioRecording,
    stopAudioRecording,
    isSpeechRecognitionSupported: browserSupportsSpeechRecognition
  }
}

export default useVoiceAssistant
